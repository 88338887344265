import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Button,
  SimpleGrid,
  VStack,
  List,
  ListItem,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { HiCheckCircle } from "react-icons/hi";

function Plans() {
  const navigate = useNavigate();

  const handleBuyNow = () => {
    navigate("/payment");
  };

  const pricingPackages = [
    {
      title: "Basic Package",
      subtitle: "For individuals looking for few connections",
      price: "₹3,000",
      features: [
        "View and contact up to 5 profiles",
        "Ideal for individuals looking for a few targeted matches",
        "Profile access for 30 days",
        "Basic customer support for any queries and assistance",
        "Privacy features to ensure your data is secure",
      ],
    },
    {
      title: "Standard Package",
      subtitle: "Perfect for those who need a moderate number of connections",
      price: "₹6,000",
      features: [
        "View and contact up to 15 profiles",
        "Suitable for those seeking more connections and options",
        "Profile access for 60 days",
        "Priority customer support for faster assistance",
        "Profile insights with key details about each match",
        "Profile recommendations based on compatibility",
      ],
    },
    {
      title: "Premium Package",
      subtitle: "Perfect for small business",
      price: "₹10,000",
      features: [
        "View and contact up to 25 profiles",
        "Designed for individuals with serious commitment in mind",
        "Profile access for 90 days",
        "Dedicated customer support for personalized assistance",
        "Advanced compatibility matching based on preferences",
        "Detailed analytics on profile engagement and potential matches",
        "Special discounts on future packages or services",
      ],
    },
    {
      title: "Unlimited Package",
      subtitle: "Most popular package",
      price: "₹15,000",
      features: [
        "Unlimited profile views and contact",
        "Best for those seriously searching for their life partner",
        "Unlimited access for 6 months",
        "VIP customer support with a personal account manager",
        "Comprehensive matchmaking services tailored to your needs",
        "Detailed analytics on profiles you engage with",
        "Exclusive offers for events and community activities",
        "Complimentary feature upgrades and priority listings",
      ],
      highlight: true,
    },
  ];

  return (
    <Box bg="gray.50" py={10} px={{ base: 4, md: 10 }}>
      <VStack spacing={4} textAlign="center" mb={10}>
        <Heading as="h1" fontSize="3xl" fontWeight="bold" color="teal.600">
          Pricing Packages
        </Heading>
        <Text fontSize="lg" color="gray.600">
          Become a member and get more benefits
        </Text>
      </VStack>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
        {pricingPackages.map((pkg, index) => (
          <Box
            key={index}
            bg={pkg.highlight ? "teal.50" : "white"}
            boxShadow="lg"
            borderRadius="lg"
            p={8}
            maxWidth={{ base: "350px", md: "300px", lg: "400px" }}
            textAlign="center"
            border={pkg.highlight ? "2px solid teal" : "none"}
            _hover={{
              boxShadow: "xl",
              transform: "scale(1.02)",
              transition: "0.3s",
            }}
            transition="0.3s"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div>
              <Heading
                as="h3"
                fontSize="xl"
                mb={2}
                color={pkg.highlight ? "teal.600" : "gray.800"}
              >
                {pkg.title}
              </Heading>
              <Text fontSize="md" color="gray.500" mb={4}>
                {pkg.subtitle}
              </Text>
              <List spacing={3} textAlign="left" mb={6}>
                {pkg.features.map((feature, i) => (
                  <ListItem key={i} fontWeight="medium" color="gray.700">
                    <Flex align="center">
                      <Icon as={HiCheckCircle} color="teal.500" mr={2} />
                      {feature}
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </div>
            <div style={{ marginTop: "30px" }}>
              <Text
                fontSize="2xl"
                fontWeight="bold"
                mb={4}
                color={pkg.highlight ? "teal.600" : "gray.800"}
              >
                Price <span>{pkg.price}</span>
              </Text>
              <Button
                colorScheme="teal"
                onClick={handleBuyNow}
                size="lg"
                width="full"
              >
                Buy Now
              </Button>
            </div>
          </Box>
        ))}
      </SimpleGrid>

      <Text textAlign="center" mt={10} color="gray.500">
        © 2024 Modern Pricing Packages. All rights reserved | Design by
        W3layouts
      </Text>
    </Box>
  );
}

export default Plans;

import { Route, Routes } from "react-router-dom";
import AuthLayout from "./components/auth/Layout";
import Login from "./Pages/auth/Longin.js";

import PageNotFound from "./extras/PageNotFound.js";
import UserLayout from "./components/user/Layout.js";
import AdminLayout from "./components/admin/Layout.js";
import Packages from "./Pages/user/Contribute/index.js";
import Matches from "./Pages/user/Matches/index.js";
import Discover from "./Pages/user/Discover/index.js";
import Loading from "./common/loading/Loding";
import ViewProfiles from "./Pages/admin/ViewProfiles.jsx";
import AddProfiles from "./Pages/admin/AddProfiles.jsx";
import DetailsProfile from "./Pages/admin/DetailsProfile.jsx";
import Payment from "./Pages/Userview/Payment/index.js";
import Plans from "./Pages/Userview/Plans.js";
import { useEffect, useState } from "react";
import UserView from "./Pages/UserView.js";
import EditProfiles from "./Pages/admin/EditProfile.jsx";
import UCard from "./Pages/UCard.js";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <Routes>
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="/admin" element={<AdminLayout />}>
        <Route path="view" element={<ViewProfiles />} />
        <Route path="add" element={<AddProfiles />} />
        <Route path="details/:id" element={<DetailsProfile />} />
        <Route path="profile/:id" element={<EditProfiles />} />
      </Route>
      <Route path="/userview/:id" element={<UserView />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/card" element={<UCard />} />
      <Route path="/" element={<UserLayout />}>
        <Route path="matches" element={<Matches />} />
        <Route path="discover" element={<Discover />} />
        <Route path="packages" element={<Packages />} />
      </Route>

      <Route path="/unauth-page" element={<div>Unauth</div>} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;

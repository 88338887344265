import React from "react";
import { Outlet, Link } from "react-router-dom";
import {
  Box,
  Flex,
  IconButton,
  useDisclosure,
  VStack,
  Text,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { AdminPages } from "../../config/Index";

const Layout = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleLinkClick = () => {
    onClose();
  };

  return (
    <Flex
      height="100vh"
      bg="white"
      boxShadow="0 4px 15px rgba(0, 0, 0, 0.1)"
      direction={{ base: "column", md: "row" }}
    >
      <Box
        display={{ base: isOpen ? "block" : "none", md: "block" }}
        width={{ base: "100%", md: "200px" }}
        padding="20px"
        bg="gray.50"
        // borderRight="1px solid"
        borderColor="gray.400"
        transition="0.3s"
        style={{
          "-webkit-box-shadow": "15px 0px 17px -8px rgba(0,0,0,0.75)",
          "-moz-box-shadow": "15px 0px 17px -8px rgba(0,0,0,0.75)",
          "box-shadow": "15px 0px 17px -8px rgba(0,0,0,0.1)",
        }}
      >
        <nav>
          <Link to="/admin/view" onClick={handleLinkClick}>
            <Text
              fontWeight={600}
              color="#3C3D37"
              style={{
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: "800",
                textTransform: "capitalize",
                fontVariant: "small-caps",
              }}
            >
              Admin
            </Text>
            <Text
              fontSize="2xl"
              fontWeight={800}
              color="purple.600"
              style={{
                fontFamily: "Dancing Script, system-ui",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            >
              Ss.shaadi.com
            </Text>
          </Link>
          <VStack
            spacing={4}
            align="stretch"
            marginTop="50px"
            as="ul"
            listStyleType="none"
            padding={0}
          >
            {AdminPages.map((page, index) => (
              <Box as="li" key={index} style={{ textTransform: "capitalize" }}>
                <Link to={page.path} onClick={handleLinkClick}>
                  <Text
                    padding="10px"
                    borderRadius="md"
                    _hover={{ bg: "#D1D2D3", color: "#3C3D37" }}
                    transition="0.2s"
                  >
                    {page.label}
                  </Text>
                </Link>
              </Box>
            ))}
          </VStack>
        </nav>
      </Box>

      <Flex
        flexGrow={1}
        direction="column"
        padding="10px"
        color="black"
        overflowY="scroll"
      >
        <Flex align="center" justify="space-between" marginBottom="30px">
          <Link to="/admin/view" onClick={handleLinkClick} />
          <IconButton
            aria-label={isOpen ? "Close sidebar" : "Open sidebar"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            onClick={onToggle}
            display={{ sd: "none", md: "none" }}
            marginLeft="10px"
            colorScheme="gray"
          />
        </Flex>

        <Outlet />
      </Flex>
    </Flex>
  );
};

export default Layout;

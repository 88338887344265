import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e9f5ff; 
`;

export const FormWrap = styled.div`
  background: #2c3e50; 
  width: 500px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  
  &:hover {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const H1 = styled.h1`
  color: #ffff; 
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  animation: fadeIn 0.5s ease; 
  font-family: 'poppins', sans-serif; 
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;


export const Label = styled.label`
  color: white;
  margin: 10px 0;
  font-size: 14px;
  text-align: left;
  width: 100%; 
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin: 5px 0 20px 0;
  border: none;
  border-radius: 5px;
  background-color: #34495e; 
  color: white;

  &:focus {
    outline: none;
    border: 1px solid black; 
    transition: border 0.3s ease; 
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.7); 
    opacity: 1;
  }
`;



export const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #02b875;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 18px; 
  margin-top: 20px;
  transition: background-color 0.3s, transform 0.3s; 

  &:hover {
    background-color: #02965f; 
    transform: scale(1.05); 
  }
`;

export const Text = styled.p`
  color: white;
  margin-top: 20px;
  font-size: 12px;
  text-align: center; 
`;

export const CustomSelect = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const SelectButton = styled.button`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #34495e; 
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s; 

  &:focus {
    outline: none;
    border-color: black; 
  }

  &:hover {
    background-color: #2c3e50; 
  }
`;

export const SelectList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

export const SelectOption = styled.li`
  padding: 12px; 
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0; 
  }

  svg {
    margin-right: 10px;
  }
`;

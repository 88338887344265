import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
  
} from "./SidebarElements";
import { HeaderPages } from "../../../../config/Index";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        {HeaderPages.map((list, i) => (
          <SidebarMenu key={i}>
            <SidebarLink to={list.id} onClick={toggle}>
              {list.label}
            </SidebarLink>
          </SidebarMenu>
        ))}
        <SideBtnWrap>
          <SidebarRoute to="/auth/login">Sign In</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;

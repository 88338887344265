export const registerFormControls = [
  {
    name: "name",
    label: "Name",
    type: "text",
    placeholder: "Enter your User Name",
    componentType: "input",
  },

  {
    name: "email",
    label: "Email",
    type: "text",
    placeholder: "Enter your Email Id",
    componentType: "input",
  },
  {
    name: "phone",
    label: "Phone",
    type: "number",
    placeholder: "Enter your Phone Number",
    componentType: "input",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "Create your Password",
    componentType: "input",
  },
  {
    name: "confirmpassword",
    label: "Confirm Password",
    type: "password",
    placeholder: "Create your Password",
    componentType: "input",
  },
];

export const loginFormControls = [
  {
    name: "email",
    label: "Email",
    type: "text",
    placeholder: "Enter your Email Id",
    componentType: "input",
  },

  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "Create your Password",
    componentType: "input",
  },
];

export const packagesFormData = [
  { id: 1, name: "Free", profiles: 5, price: 0 },
  { id: 2, name: "Standard", profiles: 10, price: 150 },
  { id: 3, name: "Premium", profiles: 15, price: 250 },
  { id: 4, name: "Unlimited", profiles: "Unlimited", price: 400 },
];

export const AdminBioFormData = [
  {
    name: "fullName",
    label: "Full Name",
    type: "text",
    placeholder: "Enter your Full Name",
    componentType: "input",
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    placeholder: "Enter your Email",
    componentType: "input",
  },

  {
    name: "phoneNumber",
    label: "Phone Number",
    type: "tel",
    placeholder: "Enter your Phone Number",
    componentType: "input",
  },
  {
    name: "dob",
    label: "Date of Birth",
    type: "date",
    placeholder: "Enter your Date of Birth",
    componentType: "input",
  },
  {
    name: "age",
    label: "Age",
    type: "number",
    placeholder: "Enter your Age",
    componentType: "input",
  },
  {
    name: "maritalStatus",
    label: "Marital Status",
    type: "select",
    placeholder: "Select your Marital Status",
    componentType: "select",
    options: [
      { value: "single", label: "Single" },
      { value: "married", label: "Married" },
      { value: "divorced", label: "Divorced" },
      { value: "widowed", label: "Widowed" },
    ],
  },
  {
    name: "height",
    label: "Height",
    type: "text",
    placeholder: "Enter your Height",
    componentType: "input",
  },
  {
    name: "weight",
    label: "Weight",
    type: "number",
    componentType: "input",
  },
  {
    name: "bloodGroup",
    label: "Blood Group",
    type: "text",
    componentType: "input",
  },
  {
    name: "complexion",
    label: "Complexion",
    type: "text",
    placeholder: "Enter your Complexion",
    componentType: "input",
  },
  {
    name: "qualification",
    label: "Qualification",
    type: "text",
    placeholder: "Enter your Qualification",
    componentType: "input",
  },
  {
    name: "jobDetails",
    label: "Job Details",
    type: "text",
    placeholder: "Enter your Job Details",
    componentType: "input",
  },
  {
    name: "income",
    label: "Income",
    type: "text",
    placeholder: "Enter your Income",
    componentType: "input",
  },
  {
    name: "father",
    label: "Father's Name",
    type: "text",
    placeholder: "Enter your Father's Name",
    componentType: "input",
  },
  {
    name: "mother",
    label: "Mother's Name",
    type: "text",
    placeholder: "Enter your Mother's Name",
    componentType: "input",
  },
  {
    name: "sisters",
    label: "Number of Sisters",
    type: "text",
    placeholder: "Enter number of Sisters ",
    componentType: "textarea",
  },
  {
    name: "brothers",
    label: "Number of Brothers",
    type: "text",
    placeholder: "Enter number of Brothers",
    componentType: "textarea",
  },
  {
    name: "residence",
    label: "Residence",
    type: "text",
    placeholder: "Enter your Residence",
    componentType: "input",
  },
  {
    name: "nativePlace",
    label: "Native Place",
    type: "text",
    placeholder: "Enter your Native Place",
    componentType: "input",
  },
  {
    name: "motherTongue",
    label: "Mother Tongue",
    type: "text",
    placeholder: "Enter your Mother Tongue",
    componentType: "input",
  },
  {
    name: "dargahVisit",
    label: "Do you visit Dargah?",
    type: "select",
    placeholder: "Select Yes or No",
    componentType: "select",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    name: "sect",
    label: "Maslak / Sect",
    type: "text",
    placeholder: "Enter your Sect",
    componentType: "input",
  },
  {
    name: "paymentStatus",
    label: "Payment Status",
    type: "select",
    placeholder: "Select Payment Status",
    componentType: "select",
    options: [
      { value: "paid", label: "paid" },
      { value: "free", label: "free" },
    ],
  },
  {
    name: "images",
    label: "Upload Images",
    type: "file",
    placeholder: "Upload images",
    componentType: "input",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "Enter  your password",
    componentType: "input",
  },
];

export const HeaderPages = [
  // { label: "Hero", id: "hero" },
  { label: "Matches", id: "matches" },
  { label: "Discover", id: "discover" },
  { label: "Packages", id: "packages" },
];

export const AdminPages = [
  {
    label: "view profiles",
    path: "/admin/view",
  },
  {
    label: "add profiles",
    path: "/admin/add",
  },
];

export const SignupSteps = [
  {
     title: "Add your Details",

    fields: [
      {
        name: "fullName",
        label: "Full Name",
        type: "text",
        componentType: "input",
      },
      { name: "email", label: "Email", type: "email", componentType: "input" },
      {
        name: "password",
        label: "Password",
        type: "password",
        componentType: "input",
      },
      {
        name: "confirmPassword",
        label: "Confirm Password",
        type: "password",
        componentType: "input",
      },
    ],
  },
  {
    title: "Personal Details",
    fields: [
      {
        name: "phoneNumber",
        label: "Phone Number",
        type: "tel",
        componentType: "input",
      },
      {
        name: "dob",
        label: "Date of Birth",
        type: "date",
        componentType: "input",
      },
      { name: "age", label: "Age", type: "number", componentType: "input" },
      {
        name: "maritalStatus",
        label: "Marital Status",
        type: "select",
        componentType: "select",
        options: [
          { value: "single", label: "Single" },
          { value: "married", label: "Married" },
          { value: "divorced", label: "Divorced" },
          { value: "widowed", label: "Widowed" },
        ],
      },
    ],
  },
  {
    title: "Physical Details",
    fields: [
      { name: "height", label: "Height", type: "text", componentType: "input" },
      {
        name: "gender",
        label: "Gender",
        type: "select",
        componentType: "select",
        options: [
          { value: "male", label: "Male" },
          { value: "female", label: "Female" },
          // { value: "prefer_not_to_say", label: "Prefer not to say" },
          { value: "other", label: "Other" },
        ],
      },
      {
        name: "weight",
        label: "Weight",
        type: "number",
        componentType: "input",
      },
      {
        name: "bloodGroup",
        label: "Blood Group",
        type: "text",
        componentType: "input",
      },
      {
        name: "complexion",
        label: "Complexion",
        type: "select",
        componentType: "select",
        options: [
          { value: "fair", label: "Fair Skin" },
          { value: "medium", label: "Medium Skin" },
          { value: "olive", label: "Olive Skin " },
          { value: "brown", label: "Brown Skin " },
        ],
      },
      {
        name: "qualification",
        label: "Qualification",
        type: "text",
        componentType: "input",
      },
      {
        name: "jobDetails",
        label: "Job Details",
        type: "text",
        componentType: "input",
      },
      { name: "income", label: "Income", type: "text", componentType: "input" },
    ],
  },
  {
    title: "Family Details",
    fields: [
      {
        name: "father",
        label: "Father's Name",
        type: "text",
        componentType: "input",
      },
      {
        name: "mother",
        label: "Mother's Name",
        type: "text",
        componentType: "input",
      },
      {
        name: "sisters",
        label: "Number of Sisters",
        type: "text",
        componentType: "textarea",
      },
      {
        name: "brothers",
        label: "Number of Brothers",
        type: "text",
        componentType: "textarea",
      },
    ],
  },
  {
    title: "Additional Details",
    fields: [
      {
        name: "residence",
        label: "Residence",
        type: "text",
        componentType: "input",
      },
      {
        name: "nativePlace",
        label: "Native Place",
        type: "text",
        componentType: "input",
      },
      {
        name: "motherTongue",
        label: "Mother Tongue",
        type: "text",
        componentType: "input",
      },
      {
        name: "dargahVisit",
        label: "Do you visit Dargah?",
        type: "select",
        componentType: "select",
        options: [
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
        ],
      },
      {
        name: "sect",
        label: "Maslak / Sect",
        type: "text",
        componentType: "input",
      },

      {
        name: "images",
        label: "Upload Images",
        type: "file",
        componentType: "input",
      },
    ],
  },
];

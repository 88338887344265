import React, { useEffect, useState } from "react";
import CommonForm from "../../common/form.js";
import { AdminBioFormData } from "../../config/Index.js";
import {
  Box,
  Heading,
  FormLabel,
  Text,
  Button,
  Spinner,
  Stack,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
// import apiURL from "../../common/apiURL.js";

const initialState = {
  fullName: "",
  email: "",
  phoneNumber: "",
  dob: "",
  maritalStatus: "",
  height: "",
  complexion: "",
  qualification: "",
  jobDetails: "",
  income: "",
  father: "",
  mother: "",
  sisters: "",
  brothers: "",
  residence: "",
  nativePlace: "",
  motherTongue: "",
  dargahVisit: "",
  sect: "",
  paymentStatus: "",
  age: "",
  images: null,
  password: "",
  weight: "",
  bloodGroup: "",
};

const EditProfiles = () => {
  const [bioFormData, setBioFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`/api/profiles`);
        const selectedProfile = data.find((bio) => bio._id === id);
        if (selectedProfile) {
          setBioFormData((prev) => ({
            ...prev,
            ...selectedProfile,
            images: null,
          }));
        } else {
          setError("Profile not found.");
        }
      } catch (err) {
        setError("Error fetching profile data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [id]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const formData = new FormData();
      for (const key in bioFormData) {
        formData.append(key, bioFormData[key]);
      }

      const response = await axios.put(`/api/profile/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      navigate(`/admin/details/${id}`);
    } catch (err) {
      setError("There was an error submitting your data. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      maxWidth="600px"
      width="100%"
      margin="0 auto"
      padding="20px"
      borderWidth="1px"
      borderRadius="8px"
      boxShadow="lg"
      backgroundColor="gray.50"
      overflow="auto"
    >
      <Heading as="h2" size="lg" mb={4} textAlign="center" color="teal.600">
        Edit Your Bio Data
      </Heading>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}
      <FormLabel fontSize="lg" fontWeight="bold" mb={2}>
        Add Bio Data
      </FormLabel>
      {loading ? (
        <Stack spacing={4} align="center">
          <Spinner size="lg" color="teal.500" />
          <Text fontSize="lg" color="teal.500">
            Loading...
          </Text>
        </Stack>
      ) : (
        <CommonForm
          formControls={AdminBioFormData}
          formData={bioFormData}
          setFormData={setBioFormData}
          buttonText={"Update"}
          isLoading={loading}
          onSubmit={onSubmit}
        />
      )}
      <Button colorScheme="teal" onClick={() => navigate(-1)} mt={4}>
        Cancel
      </Button>
    </Box>
  );
};

export default EditProfiles;

import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  SimpleGrid,
  IconButton,
  Spinner,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import UserCard from "./UserCard";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import apiURL from "../common/apiURL";
const MotionBox = motion(Box);

const UserView = () => {
  const [profiles, setProfiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [userProfile, setUserProfile] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  const bg = useColorModeValue("gray.100", "gray.900");

  // Fetch profiles from the API
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await axios.get(`/api/profiles`);
        setProfiles(response.data);
        const loggedInProfile = response.data.find(
          (profile) => profile._id === id
        );
        setUserProfile(loggedInProfile);
      } catch (error) {
        console.log("Error fetching user profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [id]);

  const handleDelete = (profileId) => {
    setProfiles((prevProfiles) =>
      prevProfiles.filter((profile) => profile._id !== profileId)
    );
  };

  const filteredUsers = useMemo(() => {
    const searchLower = searchQuery.toLowerCase();

    return profiles
      .filter((user) => user._id !== id)
      .filter((user) => user.role !== "admin")
      .filter((user) => {
        if (userProfile?.gender === "female" && user.gender === "female") {
          return false;
        } else if (userProfile?.gender === "male" && user.gender === "male") {
          return false;
        }
        return true;
      })
      .filter((user) => {
        const {
          fullName = "",
          religion = "",
          occupation = "",
          height = "",
        } = user;

        return [fullName, religion, occupation, height].some((field) =>
          field.toString().toLowerCase().includes(searchLower)
        );
      });
  }, [profiles, searchQuery, id, userProfile?.gender]);

  const openDrawer = (user) => {
    setSelectedUser(user);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    sessionStorage.removeItem("authToken");
    setUserProfile(null);
    navigate("/auth/login");
  };

  return (
    <>
      <nav>
        <Flex
          as="header"
          align="center"
          justify="space-between"
          p={4}
          bg={bg}
          boxShadow="lg"
        >
          <Flex align="center">
            <Avatar name="Company Logo" src="/logo.jpg" mr={2} />
            <Heading
              size="md"
              sx={{ fontFamily: "Dancing Script, system-ui", fontSize: "2rem" }}
            >
              Ss.shaadhi.com
            </Heading>
          </Flex>
          <Box display={{ base: "none", md: "block" }}>
            <Input
              placeholder="Search profiles..."
              width="300px"
              variant="filled"
              bg="white"
              mx={4}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              aria-label="Search profiles"
            />
          </Box>
          <Box display={{ base: "block", md: "none" }}>
            <IconButton
              aria-label="Search"
              icon={<SearchIcon />}
              variant="outline"
              colorScheme="teal"
              onClick={() => setSearchQuery(searchQuery)}
            />
          </Box>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              <Flex align="center">
                <Avatar
                  name={userProfile?.fullName}
                  src={`/${userProfile?.images[0]?.replace(/\\/g, "/")}`}
                  mr={2}
                />
                {userProfile?.fullName}
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => openDrawer(userProfile)}>
                Your Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </nav>

      {/* Main Content */}
      <main>
        <Box p={4}>
          {loading ? (
            <Flex justify="center" align="center" height="100%">
              <Spinner size="lg" />
            </Flex>
          ) : filteredUsers.length > 0 ? (
            <SimpleGrid
              columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
              spacing={4}
            >
              {filteredUsers.map((profile) => (
                <UserCard
                  key={profile._id}
                  profile={profile}
                  isSubscribed={userProfile?.paymentStatus === "paid"}
                  onDelete={handleDelete}
                  onClick={() => openDrawer(profile)}
                />
              ))}
            </SimpleGrid>
          ) : (
            <Box textAlign="center" mt={4}>
              <Heading size="md" fontFamily="'Poppins', sans-serif">
                No profiles found.
              </Heading>
            </Box>
          )}
        </Box>
      </main>

      {/* Drawer for User Profile */}
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={closeDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Your Profile</DrawerHeader>
          <DrawerBody>
            {selectedUser ? (
              <MotionBox
                p={4}
                bg="gray.50"
                borderRadius="md"
                shadow="md"
                animate={{ scale: [0.8, 1] }}
                transition={{ duration: 0.5 }}
              >
                <Flex align="center" justify="center" mb={4}>
                  <Avatar
                    size="xl"
                    src={`/${userProfile?.images[0]?.replace(/\\/g, "/")}`}
                    alt={`${selectedUser.fullName}'s profile`}
                    borderWidth="2px"
                    borderColor="teal.500"
                  />
                </Flex>
                <Heading
                  size="lg"
                  textAlign="center"
                  color="teal.600"
                  mb={2}
                  fontFamily="'Lora', serif"
                >
                  {selectedUser.fullName}
                </Heading>

                {userProfile?.paymentStatus === "paid" && (
                  <Box
                    bg="green.100"
                    color="green.800"
                    p={2}
                    borderRadius="md"
                    textAlign="center"
                    mb={4}
                    fontFamily="'Poppins', sans-serif"
                  >
                    Premium User
                  </Box>
                )}
                <Box>
                  <DetailRow label="Email:" value={selectedUser.email} />
                  <DetailRow
                    label="Phone Number:"
                    value={selectedUser.phoneNumber}
                  />
                  <DetailRow label="Date of Birth:" value={selectedUser.dob} />
                  <DetailRow
                    label="Marital Status:"
                    value={selectedUser.maritalStatus}
                  />
                  <DetailRow label="Height:" value={selectedUser.height} />
                  <DetailRow label="Weight:" value={selectedUser.weight} />
                  <DetailRow
                    label="Blood Group:"
                    value={selectedUser.bloodGroup}
                  />
                  <DetailRow
                    label="Complexion:"
                    value={selectedUser.complexion}
                  />
                  <DetailRow
                    label="Qualification:"
                    value={selectedUser.qualification}
                  />
                  <DetailRow
                    label="Job Details:"
                    value={selectedUser.jobDetails}
                  />
                  <DetailRow label="Income:" value={selectedUser.income} />
                  <DetailRow
                    label="Father's Occupation:"
                    value={selectedUser.father}
                  />
                  <DetailRow
                    label="Mother's Occupation:"
                    value={selectedUser.mother}
                  />
                  <DetailRow label="Sisters:" value={selectedUser.sisters} />
                  <DetailRow label="Brothers:" value={selectedUser.brothers} />
                  <DetailRow
                    label="Residence:"
                    value={selectedUser.residence}
                  />
                  <DetailRow
                    label="Native Place:"
                    value={selectedUser.nativePlace}
                  />
                  <DetailRow
                    label="Mother Tongue:"
                    value={selectedUser.motherTongue}
                  />
                  <DetailRow
                    label="Dargah Visit:"
                    value={selectedUser.dargahVisit}
                  />
                  <DetailRow label="Sect:" value={selectedUser.sect} />
                  <DetailRow label="Age:" value={selectedUser.age} />
                </Box>
              </MotionBox>
            ) : (
              <Flex justify="center" align="center" height="100%">
                <Spinner size="lg" />
              </Flex>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" onClick={closeDrawer}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const DetailRow = ({ label, value }) => {
  return (
    <Box mt={2} fontFamily="'Poppins', sans-serif">
      <strong>{label}</strong> <span>{value}</span>
    </Box>
  );
};

export default UserView;

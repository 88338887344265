import React, { useState } from "react";
import {
  Container,
  FormWrap,
  FormContent,
  Form,
  H1,
  Label,
  Input,
  Button,
  Text,
  CustomSelect,
  SelectButton,
  SelectList,
  SelectOption,
} from "./PaymentElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faMoneyCheckAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faPaypal,
  faCcVisa,
  faCcMastercard,
} from "@fortawesome/free-brands-svg-icons";

const Payment = () => {
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(
    "Select Payment Method"
  );
  const [showPackageOptions, setShowPackageOptions] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("Select Package");

  const paymentOptions = [
    { value: "creditCard", label: "Credit Card", icon: faCcVisa },
    { value: "debitCard", label: "Debit Card", icon: faCcMastercard },
    { value: "paypal", label: "PayPal", icon: faPaypal },
    { value: "netBanking", label: "Net Banking", icon: faMoneyCheckAlt },
    { value: "upi", label: "UPI", icon: faCreditCard },
  ];

  const packageOptions = [
    { value: "basic", label: "Basic Package" },
    { value: "standard", label: "Standard Package" },
    { value: "premium", label: "Premium Package" },
    { value: "ultimate", label: "Ultimate Package" },
  ];

  const handleSelectPayment = (option) => {
    setSelectedPaymentOption(option.label);
    setShowPaymentOptions(false);
  };

  const handleSelectPackage = (option) => {
    setSelectedPackage(option.label);
    setShowPackageOptions(false);
  };

  return (
    <Container id="Payment">
      <FormWrap>
        <FormContent>
          <Form action="#">
            <H1>Payment Details</H1>

            <Label htmlFor="name">Full Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your full name"
              required
            />

            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              required
            />

            <Label htmlFor="address">Billing Address</Label>
            <Input
              type="text"
              id="address"
              name="address"
              placeholder="Enter your billing address"
              required
            />

            <Label htmlFor="country">Country</Label>
            <Input
              type="text"
              id="country"
              name="country"
              placeholder="Enter your country"
              required
            />

            {/* Package Selection Dropdown */}
            <Label htmlFor="package">Select Package</Label>
            <CustomSelect>
              <SelectButton
                onClick={() => setShowPackageOptions(!showPackageOptions)}
              >
                {selectedPackage}
              </SelectButton>
              {showPackageOptions && (
                <SelectList>
                  {packageOptions.map((option, index) => (
                    <SelectOption
                      key={index}
                      onClick={() => handleSelectPackage(option)}
                    >
                      {option.label}
                    </SelectOption>
                  ))}
                </SelectList>
              )}
            </CustomSelect>

            {/* Payment Method Dropdown */}
            <Label htmlFor="paymentMethod">Payment Method</Label>
            <CustomSelect>
              <SelectButton
                onClick={() => setShowPaymentOptions(!showPaymentOptions)}
              >
                {selectedPaymentOption}
              </SelectButton>
              {showPaymentOptions && (
                <SelectList>
                  {paymentOptions.map((option, index) => (
                    <SelectOption
                      key={index}
                      onClick={() => handleSelectPayment(option)}
                    >
                      <FontAwesomeIcon icon={option.icon} />
                      {option.label}
                    </SelectOption>
                  ))}
                </SelectList>
              )}
            </CustomSelect>

            <Button type="submit">Pay Now</Button>
            <Text>Secure Payment Gateway</Text>
          </Form>
        </FormContent>
      </FormWrap>
    </Container>
  );
};

export default Payment;

import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Button,
  Wrap,
  WrapItem,
  useDisclosure,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Link,
  Image,
  Box,
  Grid,
  Divider,
  Stack,
} from "@chakra-ui/react";
// import apiURL from "../common/apiURL";

const UserCard = ({ profile, isSubscribed, onDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showAlert, setShowAlert] = useState(false);

  const { fullName, age, occupation, height, phoneNumber, images } = profile;

  const handleViewDetails = () => {
    if (isSubscribed) {
      onOpen();
    } else {
      setShowAlert(true);
    }
  };

  const renderProfileDetails = useMemo(() => {
    return (
      <Stack spacing={4}>
        <Box textAlign="center">
          <Image
            src={`/${images[0]?.replace(/\\/g, "/")}`}
            alt={`${fullName}'s profile image`}
            mb={4}
            borderRadius="full"
            boxSize="150px"
            objectFit="cover"
            mx="auto"
          />
        </Box>
        <Box>
          <Heading size="sm" color="blue.500">
            Personal Information
          </Heading>
          <Text>
            <strong>Full Name:</strong> {fullName}
          </Text>
          <Text>
            <strong>Email:</strong> {profile.email}
          </Text>
          <Text>
            <strong>Phone Number:</strong> {phoneNumber}
          </Text>
          <Text>
            <strong>DOB:</strong> {profile.dob}
          </Text>
          <Text>
            <strong>Age:</strong> {age} Yrs
          </Text>
          <Text>
            <strong>Height:</strong> {height}
          </Text>
          <Text>
            <strong>Weight:</strong> {profile.weight}
          </Text>
          <Text>
            <strong>Blood Group:</strong> {profile.bloodGroup}
          </Text>
        </Box>

        <Box>
          <Heading size="sm" color="blue.500">
            Family Information
          </Heading>
          <Text>
            <strong>Father:</strong> {profile.father}
          </Text>
          <Text>
            <strong>Mother:</strong> {profile.mother}
          </Text>
          <Text>
            <strong>Sisters:</strong> {profile.sisters}
          </Text>
          <Text>
            <strong>Brothers:</strong> {profile.brothers}
          </Text>
          <Text>
            <strong>Residence:</strong> {profile.residence}
          </Text>
          <Text>
            <strong>Native Place:</strong> {profile.nativePlace}
          </Text>
        </Box>

        <Box>
          <Heading size="sm" color="blue.500">
            Additional Information
          </Heading>
          <Text>
            <strong>Mother Tongue:</strong> {profile.motherTongue}
          </Text>
          <Text>
            <strong>Dargah Visit:</strong> {profile.dargahVisit}
          </Text>
          <Text>
            <strong>Sect:</strong> {profile.sect}
          </Text>
        </Box>
      </Stack>
    );
  }, [profile, fullName, age, phoneNumber, height, images]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  return (
    <>
      <Card
        w="100%"
        mx="auto"
        p={4}
        borderRadius="lg"
        boxShadow="lg"
        background="white"
        style={{ overflow: "hidden" }}
        _hover={{
          transform: "translateY(-5px)",
          boxShadow: "2xl",
          transition: "0.3s ease",
        }}
      >
        <CardHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bg="gray.50"
          p={4}
          borderRadius="lg"
        >
          <Heading size="md" fontWeight="bold" color="gray.700">
            {fullName}
          </Heading>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => onDelete(profile.id)}
            aria-label={`Delete ${fullName}`}
            variant="outline"
            _hover={{ background: "gray.100" }}
          >
            X
          </Button>
        </CardHeader>

        <CardBody>
          <Grid
            templateColumns={{
              base: "1fr",
              sm: "1fr",
              md: "1fr 1fr",
              lg: "1fr 1fr",
            }}
            gap={6}
          >
            <Box position="relative" w="100%">
              <Image
                src={`/${images[0]?.replace(/\\/g, "/")}`}
                alt={`${fullName}'s profile image`}
                boxSize={{ base: "150px", md: "200px" }}
                objectFit="cover"
                filter={isSubscribed ? "none" : "blur(5px)"}
                borderRadius="lg"
                maxWidth={"200px"}
                maxHeight={"200px"}
                style={{
                  objectPosition: "center",
                  transform: "translateY(5px) translateX(5px)",
                }}
              />
              {!isSubscribed && (
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  w="100%"
                  h="100%"
                  bg="rgba(0, 0, 0, 0.5)"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  fontWeight="bold"
                  borderRadius="lg"
                >
                  Subscribe to view
                </Box>
              )}
            </Box>

            <Box>
              <Text
                fontSize={{ base: "md", md: "lg" }}
                fontWeight="bold"
                color="gray.800"
              >
                <strong>Age:</strong> {age} Yrs
              </Text>
              <Text>
                <strong>Occupation:</strong> {occupation}
              </Text>
              <Text>
                <strong>Height:</strong> {height}
              </Text>
              <Text>
                <strong>Phone Number:</strong>{" "}
                {isSubscribed
                  ? phoneNumber
                  : `${phoneNumber?.slice(0, 2) || ""}*******`}
              </Text>
            </Box>
          </Grid>

          <Divider my={4} />

          <Wrap spacing={4} justify="center">
            <WrapItem>
              <Button
                colorScheme="blue"
                onClick={handleViewDetails}
                aria-label={
                  isSubscribed
                    ? `View full profile of ${fullName}`
                    : "Subscribe to view Details"
                }
              >
                {isSubscribed
                  ? "View Full Profile"
                  : "Subscribe to view Details 🔒"}
              </Button>
            </WrapItem>
            <WrapItem>
              <Button variant="outline">Send Interest</Button>
            </WrapItem>
            <WrapItem>
              <Button variant="outline">Send Mail</Button>
            </WrapItem>
            <WrapItem>
              <Button variant="outline">Shortlist</Button>
            </WrapItem>
          </Wrap>

          {showAlert && (
            <Alert status="warning" mt={4} borderRadius="md" boxShadow="md">
              <AlertIcon />
              Please subscribe to view the full details.
              <Button colorScheme="teal" size="sm" ml={4}>
                <Link href="/plans" _hover={{ textDecoration: "underline" }}>
                  Subscribe Now
                </Link>
              </Button>
            </Alert>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{fullName} - Full Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{renderProfileDetails}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserCard;

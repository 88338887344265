import { Button, Card, CardBody, CardFooter, Image } from "@chakra-ui/react";
import React from "react";
import { ImProfile } from "react-icons/im";
import { IoBookmark } from "react-icons/io5";

import { TbMailFast } from "react-icons/tb";
import { RiSendPlaneFill } from "react-icons/ri";

const UCard = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Card</h1>
      <br />
      <>
        <Card
          style={{
            maxWidth: "400px",
            width: "100%",
            height: "auto",
            borderRadius: "20px",
            overflow: "hidden",
          }}
          _hover={{
            transform: "translateY(-5px)",
            boxShadow: "2xl",
            transition: "0.3s ease",
          }}
        >
          <Image
            src="/logo.jpg"
            alt=""
            style={{
              width: "400px",
              height: "400px",
              objectFit: "cover",
            }}
          />
          <CardBody
            style={{
              fontFamily: "monospace",
            }}
          >
            <h1
              style={{
                fontFamily: "sans-serif",
                fontSize: "27px",
                fontWeight: "bolder",
                textTransform: "uppercase",
                // fontVariant: "small-caps",
              }}
            >
              SS Shaadi
            </h1>
            <p>Age: 28</p>
            <p>Status: Single</p>
            <p>Occupation: Software Engineer</p>
            <p>Phone number: (123) 456-7890</p>
          </CardBody>
          <CardFooter>
            <Button>
              <ImProfile />
            </Button>
            <Button>
              <TbMailFast />
            </Button>
            <Button>
              <RiSendPlaneFill />
            </Button>
            <Button>
              <IoBookmark />
            </Button>
          </CardFooter>
        </Card>
      </>
    </div>
  );
};

export default UCard;

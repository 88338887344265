import React, { useState } from 'react'
import Sidebar from './Sidebar/index'
import Navbar from './index';

const Layout = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggle = () => {
      setIsOpen(!isOpen)
    }
  return (
    <>
       <Sidebar isOpen={isOpen} toggle={toggle} />
       <Navbar toggle={toggle} />
    </>
  )
}

export default Layout
export const Membership = [
    {
      package: "Free",
      benefits: [
        "Browse up to 5 Profiles",
        "Shortlist & Send Interest",
        "Get up to 3x more matches daily",
        "Unlock access to advanced search",
        "View only Profile details",
      ],
    },
    {
      package: "Paid",
      benefits: [
        "Browse up to 20 Profiles",
        "Shortlist & Send Interest",
        "Get up to 5x more matches daily",
        "Unlock access to advanced search",
        "View contact details",
        "Make unlimited Chat",
        "Profile Boost for enhanced visibility",
        "Access to 'Recently Viewed' list",
      ],
    },
    // {
    //   package: "Gold",
    //   benefits: [
    //     "Browse unlimited profiles",
    //     "Shortlist & Send Interest",
    //     "Get up to 7x more matches daily",
    //     "Unlock all advanced search filters",
    //     "View full contact details",
    //     "Make unlimited chats and calls",
    //     "Profile Boost with top-tier visibility",
    //     "Access 'Recently Viewed' and 'Who Viewed You' lists",
    //     "Priority customer support",
    //     "Get personalized match suggestions",
    //     "Ad-free experience",
    //     "Profile verification badge",
    //   ],
    // },
  ];
import React from "react";

import {
  DiscoverContainer,
  H1,
  Wrapper,
  Card,
  Icon,
  H2,
  
} from "./DiscoverElements";

import Icon4 from "../../../media/4.png";
import Icon5 from "../../../media/5.png";
import Icon6 from "../../../media/6.png";

const Discover = () => {
  return (
    <DiscoverContainer id="discover">
      <H1>Discover</H1>
      <Wrapper>
        <Card>
          <Icon src={Icon4} alt="" />
          <H2>
           Define Your Partner
            Preferences.
          </H2>
        </Card>
        <Card>
          <Icon src={Icon5} alt="" />
          <H2>
            Browse Profiles.
          </H2>
        </Card>
        <Card>
          <Icon src={Icon6} alt="" />
          <H2>
         Send Interests & Connect.
          </H2>
        </Card>
      </Wrapper>
    </DiscoverContainer>
  );
};

export default Discover;

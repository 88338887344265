import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginFormControls } from "../../config/Index";
import CommonForm from "../../common/form";
import axios from "axios";
import SampleSignup from "../user/sampleSignup";
// import apiURL from "../../common/apiURL";

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const [formData, setFormData] = useState(initialState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await axios.get(`/api/profiles`);
        setAllUsers(response.data.profiles);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchAllUsers();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await loginUser(formData);

      if (response.data.success) {
        alert(response.data.message);
        const loggedInUser = allUsers.find(
          (user) => user.email === formData.email
        );
        console.log(loggedInUser);

        if (response.data.user.role === "admin") {
          navigate("/admin/view");
        } else {
          navigate(`/userview/${loggedInUser._id}`);
        }
      } else {
        alert(response.data.message || "Login failed. Check credentials.");
      }
    } catch (error) {
      alert(error.response?.data?.message || "An error occurred during login.");
    }
  };

  const loginUser = async (data) => {
    const response = await axios.post(`/api/login`, data);
    return response;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <p className="auth_p">Log In into your Account</p>
      <CommonForm
        formControls={loginFormControls}
        onSubmit={onSubmit}
        formData={formData}
        setFormData={setFormData}
        buttonText={"Login"}
        errors={errors}
      />

      <p className="auth_p2" style={{ marginTop: "15px" }}>
        Not yet Registered?
        <span
          onClick={openModal}
          style={{
            color: "white",
            textDecoration: "underline",
            cursor: "pointer",
            marginLeft: "5px",
          }}
        >
          Sign Up
        </span>
      </p>

      <SampleSignup isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Login;
